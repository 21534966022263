<template>
  <div class="login-register-container">
    <div class="login-header">
      <div>
        <p class="site-name" @click="$router.push({ path: '/Index' })">
          梦跳动找房
        </p>
      </div>
    </div>
    <div class="login-main">
      <div class="form-wrapper" v-if="login_register === 1">
        <div class="form-header">
          <span class="login-type-txt">{{
            login_type === 1 ? "手机登录" : "账号密码登录"
          }}</span>
          <span class="login-type-btn" @click="doToggleType">{{
            login_type === 2 ? "手机登录" : "账号密码登录"
          }}</span>
        </div>
        <el-form class="login-form" v-if="login_type === 1">
          <el-form-item>
            <el-input
              v-model="loginUuid.userName"
              prefix-icon="el-icon-mobile-phone"
              placeholder="请输入手机号码"
            ></el-input>
            <span class="get-uuid" @click="doGetLoginUuid">{{ uuidTxt }}</span>
          </el-form-item>
          <el-form-item style="margin-bottom: 5px">
            <el-input
              v-model="loginUuid.code"
              prefix-icon="el-icon-shopping-bag-1"
              placeholder="请输入验证码"
            ></el-input>
          </el-form-item>
          <p
            style="
              text-align: right;
              color: #00c297;
              font-size: 14px;
              cursor: pointer;
            "
          >
            <span @click="changePWDVisible = true">修改密码</span>
          </p>
          <el-form-item style="margin-top: 5px">
            <el-button
              type="primary"
              :disabled="loginBtn"
              :loading="loginBtn"
              style="width: 100%"
              @click="doLoginUuid"
              >立即登录</el-button
            >
          </el-form-item>
          <p class="to-register">
            <span @click="login_register = 2">还没账号？去注册</span>
            <span @click="$router.push({ path: '/Index' })"
              >不注册，先去逛逛</span
            >
          </p>
        </el-form>
        <el-form class="login-form" v-if="login_type === 2">
          <el-form-item>
            <el-input
              v-model="loginPass.username"
              prefix-icon="el-icon-user"
              placeholder="请输入用户名/手机"
            ></el-input>
          </el-form-item>
          <el-form-item style="margin-bottom: 5px">
            <el-input
              type="password"
              v-model="loginPass.password"
              prefix-icon="el-icon-shopping-bag-1"
              placeholder="请输入密码"
            ></el-input>
          </el-form-item>
          <p
            style="
              text-align: right;
              color: #00c297;
              font-size: 14px;
              cursor: pointer;
            "
          >
            <span @click="changePWDVisible = true">忘记密码？</span>
          </p>
          <el-form-item style="margin-top: 5px">
            <el-button
              type="primary"
              :disabled="loginBtn"
              :loading="loginBtn"
              style="width: 100%"
              @click="doLoginPass"
              >立即登录</el-button
            >
          </el-form-item>
          <p class="to-register">
            <span @click="login_register = 2">还没账号？去注册</span>
            <span @click="$router.push({ path: '/Index' })"
              >不注册，先去逛逛</span
            >
          </p>
        </el-form>
      </div>
      <div class="form-wrapper" style="margin-top: 50px" v-else>
        <div class="form-header">
          <span class="login-type-txt">用户注册</span>
          <span class="login-type-btn" @click="goLogin">去登录</span>
        </div>
        <el-form
          :rules="loginFormRule"
          :model="registerForm"
          class="login-form"
        >
          <el-form-item
            :required="true"
            prop="phone"
            label=" "
            label-width="15px"
          >
            <el-input
              v-model="registerForm.phone"
              prefix-icon="el-icon-mobile-phone"
              placeholder="请输入手机号码"
            ></el-input>
            <span class="get-uuid" @click="doGetRegisterUuid">{{
              uuidTet
            }}</span>
          </el-form-item>
          <el-form-item
            :required="true"
            prop="vcode"
            label=" "
            label-width="15px"
          >
            <el-input
              v-model="registerForm.vcode"
              prefix-icon="el-icon-shopping-bag-1"
              placeholder="请输入验证码"
            ></el-input>
          </el-form-item>
          <el-form-item
            :required="true"
            prop="pwd"
            label=" "
            label-width="15px"
          >
            <el-input
              type="password"
              v-model="registerForm.pwd"
              prefix-icon="el-icon-shopping-bag-1"
              placeholder="请输入密码"
            ></el-input>
          </el-form-item>
          <el-form-item
            :required="true"
            prop="pwd"
            label=" "
            label-width="15px"
          >
            <el-input
              type="password"
              v-model="pwd"
              prefix-icon="el-icon-shopping-bag-1"
              placeholder="请输入确认密码"
            ></el-input>
          </el-form-item>
          <el-form-item label=" " label-width="15px">
            <el-input
              v-model="registerForm.code"
              prefix-icon="el-icon-shopping-bag-1"
              placeholder="请输入推荐码"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              style="width: 100%"
              @click="doRegister"
              :loading="regisBtn"
              :disabled="regisBtn"
              >立即注册</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="footer">
      <img
        style="vertical-align: middle; margin-right: 5px"
        src="../assets/gongan.png"
        alt=""
      />
      <span style="margin-right: 20px">苏公网安备32010602010867</span>
      <span>客服电话：18551643873</span>
    </div>

    <!-- 修改密码 -->
    <el-dialog
      width="30%"
      title="修改密码"
      :visible="changePWDVisible"
      @close="doCancelChangePWD"
    >
      <el-form label-width="100px" size="small">
        <el-form-item label="手机号">
          <el-input
            v-model="changePwdForm.phone"
            placeholder="请输入手机号"
          ></el-input>
        </el-form-item>
        <el-form-item label="新密码" required>
          <el-input
            v-model="changePwdForm.pwd"
            type="password"
            placeholder="请输入新密码"
          ></el-input>
        </el-form-item>
        <el-form-item label="确认新密码" required>
          <el-input
            v-model="pwdCheck"
            type="password"
            placeholder="请再次输入新密码"
          ></el-input>
        </el-form-item>
        <el-form-item label="验证码" required>
          <el-input
            v-model="changePwdForm.code"
            placeholder="请输入验证码"
          ></el-input>
          <span class="get-uuid" @click="doGetPwdUuid">{{ pwdText }}</span>
        </el-form-item>
      </el-form>

      <div slot="footer">
        <el-button size="small" @click="doCancelChangePWD">取消</el-button>
        <el-button size="small" type="primary" @click="doConfirmChangePWD"
          >确定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import md5 from "md5";
export default {
  name: "login-regiater",
  data() {
    return {
      loginBtn: false,
      changePWDVisible: false,
      pwdText: "获取验证码",
      changePWDTimer: null,
      pwdCheck: "",
      loginFormRule: {
        phone: [{ required: true, message: "必填", trigger: "blur" }],
        vcode: [{ required: true, message: "必填", trigger: "blur" }],
      },
      changePwdForm: {
        phone: "",
        pwd: "",
        uuid: "",
        code: "",
      },
      login_register: 1, // 1 登录 2 注册
      login_type: 1, // 1 验证码登录 2 账号密码登录
      uuidTet: "获取验证码",
      uuidTxt: "获取验证码",
      registerTimer: null,
      loginTimer: null,
      regisBtn: false,
      registerForm: {
        phone: "",
        pwd: "",
        vcode: "",
        uuid: "",
        code: "",
      },
      pwd: "",
      loginUuid: {
        code: "",
        userName: "",
        uuid: "",
      },
      loginPass: {
        password: "",
        username: "",
      },
    };
  },
  mounted() {
    localStorage.clear();
  },
  methods: {
    doGetPwdUuid() {
      if (!this.changePwdForm.phone || this.changePwdForm.phone.length < 11) {
        this.$message.warning("请输入手机号!");
        return;
      }
      this.$request
        .get(`/api/sms/sedCode/${this.changePwdForm.phone}`)
        .then((res) => {
          if (res.code === 200) {
            this.changePwdForm.uuid = res.data.uuid;
            this.$message.success("发送成功！");
          } else {
            this.$message.warning("发送失败！");
          }
          let i = 10;
          this.changePWDTimer = setInterval(() => {
            if (i === 0) {
              clearInterval(this.changePWDTimer);
              this.changePWDTimer = null;
              this.pwdText = "获取验证码";
              return;
            }
            this.pwdText = i + " s";
            i--;
          }, 1000);
        });
    },
    doConfirmChangePWD() {
      if (!this.changePwdForm.phone) {
        this.$message.warning("请输入手机号码！");
        return;
      }
      if (!this.changePwdForm.pwd || !this.pwdCheck) {
        this.$message.warning("请输入密码！");
        return;
      }
      if (!this.changePwdForm.code) {
        this.$message.warning("请输入验证码！");
        return;
      }
      if (this.changePwdForm.pwd !== this.pwdCheck) {
        this.$message.warning("两次密码不一致！");
        return;
      }
      const data = {
        ...this.changePwdForm,
        pwd: md5(this.changePwdForm.pwd),
      };
      this.$request.post("/api/user/updatePwd", data).then((res) => {
        if (res.code === 200) {
          this.$message.success("修改成功！");
        } else {
          this.$message.warning(res.msg);
        }
        this.doCancelChangePWD();
      });
    },
    doCancelChangePWD() {
      this.changePwdForm = {
        phone: "",
        pwd: "",
        uuid: "",
        code: "",
      };
      clearInterval(this.changePWDTimer);
      this.changePWDTimer = null;
      this.pwdText = "获取验证码";
      this.pwdCheck = "";
      this.changePWDVisible = false;
    },
    async doRegister() {
      if (this.registerForm.pwd !== this.pwd) {
        this.$message.warning("两次密码不一致！");
        return;
      }
      const data = {
        ...this.registerForm,
        pwd: md5(this.registerForm.pwd),
      };
      this.regisBtn = true;
      this.$request.post("/api/user/register", data).then((res) => {
        if (res.code == 200) {
          this.regisBtn = false;
          this.$message.success("注册成功！");
          this.registerForm = {
            phone: "",
            pwd: "",
            vcode: "",
            uuid: "",
            code: "",
          };
          this.loginBtn = true;
          this.$request
            .post("/api/user/pwdLogin", {
              username: data.phone,
              password: data.pwd,
            })
            .then((res) => {
              if (res.code === 200) {
                localStorage.setItem("token", res.data.token);
                localStorage.setItem("avatar", res.data.avatar);
                localStorage.setItem("userName", res.data.userName);
                this.$router.push({ path: "/user-account" });
              } else {
                this.$message.warning(res.msg);
              }
              this.loginBtn = false;
            });
          this.login_register = 1;
        } else {
          this.regisBtn = false;
          this.$message.warning(res.msg);
        }
      });
    },
    doGetLoginUuid() {
      if (this.loginTimer) return;
      if (!this.loginUuid.userName || this.loginUuid.userName.length < 11) {
        this.$message.warning("请输入手机号!");
        return;
      }
      this.$request
        .get(`/api/sms/sedCodeLogin/${this.loginUuid.userName}`)
        .then((res) => {
          if (res.code === 200) {
            this.loginUuid.uuid = res.data.uuid;
            this.$message.success("发送成功！");
            let i = 10;
            this.loginTimer = setInterval(() => {
              if (i === 0) {
                clearInterval(this.loginTimer);
                this.loginTimer = null;
                this.uuidTet = "获取验证码";
                return;
              }
              this.uuidTxt = i + " s";
              i--;
            }, 1000);
          } else {
            this.$message.warning(res.msg);
          }
        });
    },
    doGetRegisterUuid() {
      if (this.registerTimer) return;
      if (!this.registerForm.phone || this.registerForm.phone.length < 11) {
        this.$message.warning("请输入手机号!");
        return;
      }
      this.$request
        .get(`/api/sms/sedRegister/${this.registerForm.phone}`)
        .then((res) => {
          if (res.code === 200) {
            this.registerForm.uuid = res.data.uuid;
            this.$message.success("发送成功！");
            let i = 10;
            this.registerTimer = setInterval(() => {
              if (i === 0) {
                clearInterval(this.registerTimer);
                this.registerTimer = null;
                this.uuidTet = "获取验证码";
                return;
              }
              this.uuidTet = i + " s";
              i--;
            }, 1000);
          } else {
            this.$message.warning(res.msg);
          }
        });
    },
    doToggleType() {
      this.login_type = this.login_type === 1 ? 2 : 1;
    },
    goLogin() {
      this.login_register = 1;
    },
    doLoginUuid() {
      this.loginBtn = true;
      this.$request.post("/api/user/codeLogin", this.loginUuid).then((res) => {
        if (res.code === 200) {
          this.$message.success("登录成功！");
          localStorage.setItem("token", res.data.token);
          localStorage.setItem("avatar", res.data.avatar);
          localStorage.setItem("userName", res.data.userName);
          this.$router.push({ path: "/user-account" });
        } else {
          this.$message.warning(res.msg);
        }
        this.loginBtn = false;
      });
    },
    doLoginPass() {
      let data = {
        ...this.loginPass,
        password: md5(this.loginPass.password),
      };

      this.loginBtn = true;
      this.$request.post("/api/user/pwdLogin", data).then((res) => {
        if (res.code === 200) {
          this.$message.success("登录成功！");
          localStorage.setItem("token", res.data.token);
          localStorage.setItem("avatar", res.data.avatar);
          localStorage.setItem("userName", res.data.userName);
          this.$router.push({ path: "/user-account" });
        } else {
          this.$message.warning(res.msg);
        }
        this.loginBtn = false;
      });
    },
  },
};
</script>
<style lang='scss'>
@import "../styles/variable.scss";
.get-uuid {
  position: absolute;
  right: 10px;
  top: 0;
  color: $mainColor;
  cursor: pointer;
}
.login-register-container {
  user-select: none;
  height: 100%;
  .login-header {
    padding: 10px 0;
    border-bottom: 2px solid $mainColor;
    > div {
      width: 1200px;
      margin: 0 auto;
    }
    .site-name {
      font-size: 32px;
      font-weight: bold;
      color: $mainColor;
      cursor: pointer;
    }
  }
  .login-main {
    position: relative;
    min-width: 1200px;
    height: 706px;
    background: url("../assets/bg.jpg") center center no-repeat;
    background-color: #fbfbfb;
    .form-wrapper {
      padding: 50px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -60%);
      width: 600px;
      background-color: #fff;
      box-shadow: 0 0 5px 3px rgba($color: #000000, $alpha: 0.2);
      border-radius: 5px;
      .form-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #333;
        .login-type-txt {
          font-size: 24px;
        }
        .login-type-btn {
          font-size: 14px;
          cursor: pointer;
          &:hover {
            color: $mainColor;
          }
        }
      }
      .login-form {
        .el-form-item {
          margin: 30px 0;
        }
        .el-input__inner {
          border-radius: 0;
        }
        .el-button {
          border-radius: 0;
        }
        .el-button--primary,
        .el-button--primary:focus,
        .el-button--primary:hover {
          background-color: $mainColor;
          border-color: $mainColor;
        }
        .el-input.is-active .el-input__inner,
        .el-input__inner:focus {
          border-color: $mainColor;
        }
        .get-uuid {
          position: absolute;
          right: 10px;
          top: 0;
          color: $mainColor;
          cursor: pointer;
        }
        .to-register {
          display: flex;
          justify-content: space-between;
          font-size: 14px;
          cursor: pointer;
          color: $textColor;
        }
      }
    }
  }
}
</style>
